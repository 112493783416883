<template>
  <div class="Wage">
    <div class="top-title">
      <div class="top-text">佣金工资待发放 <span v-if="Separatetype == '2'?true:false" style="float:right;color:red">账户余额：{{listpar.balance}}</span></div>
      
        <el-form ref="form" label-width="100px">
          <div class="top-box">
         <el-form-item label="会员名称" >
                  <el-input v-model="seeks.a"></el-input>
          </el-form-item>
         <el-form-item label="工厂名称" >
                  <el-input v-model="seeks.d"></el-input>
          </el-form-item>

      <div class="Newsinvite-form">
          <el-form-item label="工作性质：">
            <el-select v-model="seeks.b" placeholder="请选择工作性质：">
              <el-option
                v-for="(r, i) in seeklist.one"
                :key="i"
                :label="r.label"
                :value="r.value"
              ></el-option
            ></el-select>
          </el-form-item>
        </div>

          <el-form-item label="年月筛选">
              <el-date-picker
                    v-model="seeks.c"  type="month"
                    format="yyyy 年 MM 月" value-format="yyyy-MM"
                    placeholder="选择月">
                  </el-date-picker>
           </el-form-item>
           
              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="seek()"
                >搜索</el-button>
            </div>
        </el-form>
    </div>
         <div class="table-tap">
       <ul>
          <li v-for="(item,i) in taplist.one" :key="i"> <span @click="tap(item)" :class="classactive == item.tapid?'active':''">{{item.tapname}}</span></li>
        </ul>
    </div>
   
    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStyle" @selection-change="handleSelectionChange" v-loading="loading">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="swk_name" label="会员姓名"> </el-table-column>
        <el-table-column prop="bsr_employername" label="工厂名称"> </el-table-column>
        <el-table-column prop="recruit_title" label="标题"> </el-table-column>
        <el-table-column prop="work_duty" label="工作性质"> </el-table-column>
        <el-table-column prop="wkhours_sum" label="工时">
            <template slot-scope="scope">
               <span>{{parseFloat(scope.row.wkhours_sum).toFixed(1)}}</span>
            </template>
           </el-table-column>
        <el-table-column v-if="classactive == 0?true:false" prop="feetotalper" label="费用"> </el-table-column>
        <el-table-column v-if="classactive == 0?true:false" prop="feetotal_sum" label="总费用"> 
           <template slot-scope="scope">
               <span>{{parseFloat(scope.row.feetotal_sum).toFixed(2)}}</span>
            </template>
        </el-table-column>

        <el-table-column v-if="classactive == 0?false:true" prop="salaryper" label="工资"> </el-table-column>
        <el-table-column v-if="classactive == 0?false:true" prop="salary_sum" label="总工资"> 
           <template slot-scope="scope">
               <span>{{parseFloat(scope.row.salary_sum).toFixed(2)}}</span>
            </template>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div v-if="classactive == 0?true:false" style="font-size:18px;color:red;float:right;padding-right:50px">小计：{{feetotal_sum}}</div>
    </div>

                <!-- 一键发放工资 -->
      <div class="wage-btn">
        <el-button type="warning" style=" background-image: linear-gradient(to right,  #3ecc7c , #28cfb3);" v-if="classactive==0?true:false" @click="yijianyongjin()">一键发放佣金 + 总佣金数</el-button>
       <el-button type="primary" style=" background-image: linear-gradient(to right,  #3ecc7c , #28cfb3);"  v-if="classactive==1?true:false" @click="yijiangongzi()">一键发放工资</el-button></div>
  </div>
</template>
<script>
// 假数据
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"

export default {
  data() {
    return {
      // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,

      // 下拉框
      seeks: {},
      seeklist:{
        one:[
          {value:0,
          label:'全部'},
          {value:2,
          label:'长期工'},
          {value:1,
          label:'临时工'}
        ]
      },
      multipleSelection:[],

      // 判断是平台登录还是商户登录
      Separatetype:this.$common.baseGetSeparate(),
      // 数据
      list:[],
      listpar:{},
      feetotal_sum:'',
        classactive:0,
        classactives:1,
        taplist:{
         one:[
            {
               tapname:'待发放佣金',
               tapid:0,
               tapids:1,
            },
            {
               tapname:'待发放工资',
               tapid:1,
               tapids:0,
            }, 
          ],},

    };
  },
  created() {
     this.getSong();
     this.Separatetype == '2'?this.getSongs():this.$alert('商家才能操作', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
              console.log("平台");
          }
        });
  },
  mounted() {},

  methods: {

    // //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,

        bizCatalog:'TodoWorkEntity',
        handleMode:'fetchallWkHourCommissionSettle',
        salary_status:this.classactive,
        commission_status:this.classactives,
        query:{
          memberName:this.seeks.a, 
          workType:this.seeks.b, 
          yearMonth:this.seeks.c, 
          employerName:this.seeks.d, 
        }, })
       
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.feetotal_sum = res.Result.feetotal_sum
           this.total = res.Result.count
           this.loading = false
      },


    //查询接口
      async getSongs(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'ServicerEntity',
        bizId:this.$common.baseGetID(),
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;    
          this.listpar = res.Result;
      },

    // 搜索栏
    seek(){
      this.currentPage = 1;
        this.getSong()
    },
  // 选择发放
    handleSelectionChange(val){
      this.multipleSelection = val;
    },

    //弹窗
      yijianyongjin(){
          this.$confirm('是否确认一键发放佣金+总佣金？', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.yijianyongjins()
          }).catch(action => {
            console.log("取消");
          })
      },
    // 一键发放佣金
  async yijianyongjins(){
   // 转化为新数组
     var multipleSelections = this.multipleSelection.map( (item, index) => {
     	return item.workid
     })
      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'TodoWorkEntity',
        handleMode:'dispatchMoney',
        catalog:'1',
        target:multipleSelections
        })

        if (res.ErrCode == "BalanceNotEnough") {
               this.$alert('余额不足请充值', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
              this.getSong()
              this.getSongs()
          }
        });
        }else{
        if(this.$common.verifyAjaxResult(res,false)==false) return;
                this.$alert('发放成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.getSong()
            this.getSongs()
          }
        });

        }

    },
        //弹窗
      yijiangongzi(){
          this.$confirm('是否确认一键发放工资？', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.yijiangongzis()
          }).catch(action => {
            console.log("取消");
          })
      },
    // 一键发放工资
  async  yijiangongzis(){
          // 转化为新数组
        var multipleSelections = this.multipleSelection.map( (item, index) => {
        	return item.workid
        })
      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'TodoWorkEntity',
        handleMode:'dispatchMoney',
        catalog:'0',
        target:multipleSelections
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.$alert('发放成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
              this.getSong()
          }
        });
  },
      // 选项卡
      tap(e){
      this.currentPage = 1;
       this.classactive = e.tapid
       this.classactives = e.tapids
       this.getSong()
      },

    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
.wage-btn{
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding-top: 50px
}

.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}
@import "../../assets/css/liststyle.css";
</style>